import CryptoJS from 'crypto-js';

const decryptToken = (encryptedToken: string, key: string) => {
  try {
    const urlDecoded = decodeURIComponent(encryptedToken);

    const decryptedBytes = CryptoJS.AES.decrypt(urlDecoded, key);

    const decryptedToken = decryptedBytes.toString(CryptoJS.enc.Utf8);

    return decryptedToken;
  } catch (error) {
    console.error('Error decrypting token:', error);
    return null;
  }
};

export default decryptToken;
